import {routeNavigate} from "@/router";

export const routes = [
    //课程
    {
        path: '/course',
        name: 'course',
        meta:{title:'课程'},
        component: () => import('@/views/course/index.vue'),
    },
    //我创建的课程-new
    {
        path: '/course/myCreateList',
        name: 'myCreateList',
        meta:{title:'课程',login:true},
        component: () => import('@/views/course/myCreateList.vue'),
    },
    //我加入的课程-new
    {
        path: '/course/myJoinList',
        name: 'myJoinList',
        meta:{title:'课程',login:true},
        component: () => import('@/views/course/myJoinList.vue'),
    },
    // 创建课程模板-new
    { name: 'teachingCourseCreateNew',meta:{title:'创建课程模板',login:true},path: '/course/course/',redirect: '/course/course/create'
        , component: () => import('@/views/course/course/headerSave.vue')
        , children: [
            { name: 'teachingCourseBase',meta:{title:'创建课程'},path: 'create',component: () => import('@/views/course/course/teachingCourseBase.vue') },
        ],
    },
    // 课程详情-new
    { name: 'teachingCourseDetailNew',meta:{title:'课程详情',login:true},path: '/course/course/:course_id(\\d+)/detail',props:true, component: () => import('@/views/api/course/detail.vue')
    , children: [
        // { name: 'teachingCourseDetailMenu',meta:{title:'课程目录'},path: 'study/:list_id(\\d+)?',component: () => import('@/views/api/teach_plan/course/c/menu.vue')},
        { name: 'teachingCourseDetailComment',meta:{title:'学员评价'},path: 'comment',component: {
                props:['course_id'],
                template:'<comment type="course" :where="{id:course_id}"></comment>',
                components:{
                    comment:()=>{return import('@/views/api/com/comment.vue')},
                }
            }
        },
    ]
},
    { name: 'totalDetails',meta:{title:'班课详情'},path: '/course/questOther/totalDetail',component: () => import('@/views/course/questOther/totalDetail.vue') },

    // 编辑课程-new
    { name: 'teachingCourseEditNew',meta:{title:'编辑课程模板',login:true},props:true,path: '/course/course/:course_id(\\d+)',redirect: '/course/course/:course_id(\\d+)/edit'
        , component: {
            props:['course_id'],
            template:'<headerSave ref="headerSave" type="course" :where="{id:course_id}"></headerSave>',
            components:{
                headerSave:()=>{return import('@/views/course/course/headerSave.vue')},
            },
            methods:{
                routeUpdate:function(){
                    if(this.course_id > 0){
                        // this.$http.post('/xapi/course.course/detail' ,{id:this.course_id}).then(({data})=>{
                        this.$http.post('/xapi/course.course/detail_simplen' ,{id:this.course_id}).then(({data})=>{
                            if((data.powers || {}).plan_create){
                                this.$refs.headerSave.rightButtonLists = [{name:'CourseGeneratePlan',callback(){
                                        routeNavigate({courseId:data.id},'/teachPlan/course/create');
                                    },title:'生成教案'},]
                            }
                        });
                    }
                },
            },
        }
        , children: [
            { name: 'teachingCourseBaseEdit',meta:{title:'编辑课程'},path: 'edit',component: () => import('@/views/course/course/teachingCourseBase.vue') },
            { name: 'teachingCourseStudy',meta:{title:'学生管理'},path: 'study',component: () => import('@/views/course/course/teachingCourseStudy.vue')},
            { name: 'teachingCourseTeach',meta:{title:'教师管理'},path: 'teach',component: () => import('@/views/course/course/teachingCourseTeach.vue') },
            { name: 'teachingCourseCont',meta:{title:'课程内容'},path: 'cont',component: () => import('@/views/course/course/courseCont.vue') },
            { name: 'teachingCourseListCreate',meta:{title:'添加条目'},path: 'list',component: () => import('@/views/course/course/listSave.vue') },
            { name: 'teachingCourseListEdit',meta:{title:'编辑条目'},path: 'list/:list_id(\\d+)',props:true,component: () => import('@/views/course/course/listSave.vue') },
            { name: 'teachingCourseTask',meta:{title:'作业列表'},path: 'task', component: () => import('@/views/course/course/taskList.vue')  },
            { name: 'teachingCourseTaskCreate',meta:{title:'添加作业'},path: 'task/save', component: () => import('@/views/course/questOther/taskSave.vue')  },
            { name: 'teachingCourseTaskInfo',meta:{title:'做作业'},path: 'task/:other_id(\\d+)', component: () => import('@/views/course/questOther/detail.vue')  },
            { name: 'teachingCourseTaskEdit',meta:{title:'编辑作业'},path: 'task/:other_id(\\d+)/save', component: () => import('@/views/course/questOther/taskSave.vue')  },
            { name: 'teachingCourseTaskCensus',meta:{title:'作业统计详情'},path: 'task/:other_id(\\d+)/census',component: () => import('@/views/course/questOther/census.vue') },
            { name: 'teachingCourseTaskQuestion',meta:{title:'课程作业题目管理'},path: 'task/:other_id(\\d+)/question', component: () => import('@/views/course/questOther/chooseList.vue')  },
        ],
    },
    //课程更多
    {
        name: 'courseMore',
        path: "/course/moreCourse",
        meta:{title:'更多课程'},
        component: () => import('@/views/course/moreCourse.vue'),
    },
    //课程详情
    {
        name: 'courseDetail',
        path: "/course/:course_id(\\d+)/detail",
        meta:{title:'课程详情'},
        props: true,
        component: () => import('@/views/api/course/detail.vue')
        , children: [
            // { name: 'courseDetailMenu',meta:{title:'课程目录'},path: 'study/:list_id(\\d+)?',component: () => import('@/views/api/teach_plan/course/c/menu.vue')},
            { name: 'courseDetailComment',meta:{title:'学员评价'},path: 'comment',component: {
                    props:['course_id'],
                    template:'<comment v-bind="{...$attrs}" type="course" :where="{id:course_id}"></comment>',
                    components:{
                        comment:()=>{return import('@/views/api/com/comment.vue')},
                    }
                }
            },
        ]
    },
    { name: 'courseDetailOrderSure',meta:{title:'立即报名',login:true},path: '/course/:course_id(\\d+)/detail/order/:order_id(\\d+)',props:true,component: {
            template:'<div><breadcrumb></breadcrumb><order-sure :order_id="order_id"></order-sure></div>',
            props:['order_id'],
            components:{
                orderSure :() => import('@/views/api/com/orderSure.vue')
            }
        }
    },
    { name: 'courseCourseStudySpeed',path: "/course/course/study/:study_user_id(\\d+)?/speed",meta:{title:'学习进度',login:true},component: () => import('@/views/me/speed.vue'),},
    
    { name: 'courseDetailStudent',meta:{title:'学员列表',login:true},path: '/course/:course_id(\\d+)/detail/student',props:true,component: () => import('@/views/api/course/student.vue')},
    { name: 'courseDetailTeach',meta:{title:'老师列表',login:true},path: '/course/:course_id(\\d+)/detail/teach',props:true,component: () => import('@/views/api/course/teach.vue')},
    //文章详情
    {
        name: 'courseRead',
        path: "/course/readDrticle",
        meta:{title:'文章详情'},
        component: () => import('@/views/course/readDrticle.vue'),
    },
    {
        name: 'courseStudy',
        path: "/course/study",
        meta:{title:'开始学习',login:true},
        component: () => import('@/views/course/study.vue'),
    },
    {
        name: 'courseExercises',
        path: "/course/exercises",
        meta:{title:'习题练习',login:true},
        component: () => import('@/views/problemList/student/practiceExam.vue'),
    },
]